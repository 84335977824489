import React, { useState } from "react";
import DropDown from "../../components/Inputs/DropDown";
import SearchInput from "../../components/Inputs/SearchInput";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { DummyData, Allinvoices } from "../../DummyData/DummyData";
import { IoCopyOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import "./invoicing.css";
import BackGroudLight from "../../components/Buttons/BackGroudLight";

const Invoicing = () => {
  const navigate = useNavigate()
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  const option =[{
    name : "Wave Net demo",
    url : "invoiceassociate"
  }]
  const handleChange = (e) => {
    setSelectedProducts(e.value);
    navigate(`/${e.value.url}`);
  };


  return (
    <div className="invoicing_section">
      <div className="flex between">
        <h4>All Invoices</h4>
        <div className="flex gap-4 center">
          <div className={`flex gap-3 center`}>
            <div className="light_heading">Due:</div>
            <span className="red_head">Rs 15192</span>
            <div className="light_heading">Balance:</div>
            <span className="green_head">Rs 15192</span>
            
          </div>
        </div>
      </div>

      <div className="mt-5 flex between">
        <SearchInput
          placeholder={"User ID, User Name, Circuit Id, First Name"}
        />
        <div className="d-flex gap-2">
          <div className={`filter_dropdown`}>
            <DropDown placeholder={"All Zones"} value={selectedProducts} option={option} onChange={handleChange} />
          </div>
          <div className={`filter_dropdown`}>
            <DropDown placeholder={"Status"} />
          </div>
          <div>
            <BackGroudLight text={"Actions"} />
          </div>
        </div>
      </div>

      <div>
        <BorderedTable>
          <div className="mt-5">
            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
            <DataTable
              value={Allinvoices}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column
                field=""
                header="Invoice No."
                body={(rowData) => (
                  <div className="flex gap-2 center dark_content">
                    {rowData.invoiceno}
                    <IoCopyOutline />
                  </div>
                )}
              ></Column>
              <Column field="name" header="First Name"></Column>
              <Column field="zone" header="Zone"></Column>
              <Column field="balanece" header="Balance"></Column>
              <Column field="total" header="Total"></Column>
              <Column field="invoiceid" header="Invoice ID"></Column>
              <Column
                header="Status"
                body={(rowData) => (
                  <div className="d-flex center">
                    <div
                      className={`statusBg ${
                        rowData?.status === "Paid"
                          ? "statusPaid"
                          : rowData?.status === "Pending"
                          ? "statusPending"
                          : "statusUnpaid"
                      }`}
                      onClick={() => navigate("")}
                    >
                      {rowData?.status}
                    </div>
                  </div>
                )}
              ></Column>

              <Column
                field=""
                header="Created On"
                body={(rowData) => (
                  <>
                    {" "}
                    <div className="dark_content">{rowData.createtime}</div>
                    <div>{rowData.createdate}</div>
                  </>
                )}
              ></Column>
              <Column field="description" header="Description"></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </div>
  );
};

export default Invoicing;
