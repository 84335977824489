import React from 'react'
import style from "./input.module.css"
import { MdOutlineFileUpload } from 'react-icons/md'

const InputUpload = ({placeholder, ...props}) => {
  return (
    <div  className='position-relative'>
        <input type='file' hidden id="upload"/>
      
       <label htmlFor='upload' className={style.upload} placeholder={placeholder}>
           
           </label>
      
        <div className={style.uploadIcon}>
        <MdOutlineFileUpload size={20} />
        </div>
      
    </div>
  )
}

export default InputUpload
