import React, { useState } from "react";
import SelectDropDown from "../../components/Inputs/SelectDropDown";
import DropDown from "../../components/Inputs/DropDown";
import Calender from "../../components/Common/Calender";
import SearchInput from "../../components/Inputs/SearchInput";
import BackGroudLight from "../../components/Buttons/BackGroudLight";
import { WavesNet } from "../../DummyData/DummyData";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./invoicing.css";
import { useNavigate } from "react-router";
import { IoCopyOutline } from "react-icons/io5";

const Invoicesassociated = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  const navigate = useNavigate();

  return (
    <div className="invoicesassociated_section">
      <div className="flex between">
        <h4>Invoices Associated with Payments</h4>
      </div>

      <div className="mt-5 flex between">
        <SearchInput
          placeholder={"User ID, User Name, Circuit Id, First Name"}
        />
        <div className="d-flex gap-2">
          <div className={`filter_dropdown`}>
            <DropDown placeholder={"All Zones"} />
          </div>
          <div className={`filter_dropdown`}>
            <DropDown placeholder={"Status"} />
          </div>
          <div>
            <BackGroudLight text={"Actions"} />
          </div>
        </div>
      </div>
      {/* <div className="d-flex row  mt-4">
        <div className="col-md-4 d-flex align-items-center  gap-3 ">
          <span className=" text-nowrap text-align-center">
            <h5>Zone</h5>
          </span>
          <div className="w-100 ">
            <SelectDropDown placeholder={"Wave Net Demo"} />
          </div>
        </div>
        <div className="col-md-3 ">
          <div className="flex gap-3 sms_calendar">
            <div>
              <Calender placeholder={"From"} />
            </div>
            <div>
              <Calender placeholder={"To"} />
            </div>
          </div>
        </div>

        <div className="col-md-1 ">
          <div className="w-100 ">
            <SelectDropDown placeholder={"All"} />
          </div>
        </div>
        <div className="col-md-1">
          <BackGroudLight text={"Filter"} />
        </div>
      </div> */}

      <div>
        <BorderedTable>
          <div className="mt-4">
            <div className="flex justify-content-center align-items-center mb-4 gap-2"></div>
            <DataTable
              value={WavesNet}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column
                field=""
                header="Username"
                body={(rowData) => (
                  <div className="flex">{rowData.userName}</div>
                )}
              ></Column>
              <Column
                field="invoiceno"
                header="Invoice No."
                className="dark_content"
              ></Column>
              <Column field="firstname" header="First Name"></Column>
              <Column field="zone" header="Zone"></Column>
              <Column field="balance" header="Balance"></Column>
              <Column field="total" header="Total"></Column>
              <Column field="invoiceid" header="Invoice ID"></Column>
              <Column
                header="Status"
                body={(rowData) => (
                  <div className="d-flex center">
                    <div
                      className={`statusBg ${
                        rowData?.status === "Unpaid"
                          ? "statusUnpaid"
                          : "statusUnpaid"
                      }`}
                      onClick={() => navigate("")}
                    >
                      {rowData?.status}
                    </div>
                  </div>
                )}
              ></Column>

              <Column
                field=""
                header="Created On"
                body={(rowData) => (
                  <div>
                    <div className="dark_content">
                      {rowData.createtime.split(",")[0]}
                    </div>
                    <div style={{ marginTop: "0px" }}>
                      {rowData.createtime.split(",")[1]}
                    </div>
                    {/* You can adjust the marginTop value as needed */}
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </div>
  );
};

export default Invoicesassociated;
