import { InputText } from "primereact/inputtext";
import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router";
import DropDown from "../../components/Inputs/DropDown";
import BackGroudLight from "../../components/Buttons/BackGroudLight";
import './bandwidth.css'

const AddBandWidth = () => {
  const navigate = useNavigate();
  return (
    <div className="Add_bandWidth_section">
      <div
        style={{ color: "#0E1073", fontWeight: "600" }}
        onClick={() => navigate("/bandwidth")}
        className="flex center gap-1 pointer"
      >
        <FaArrowLeft /> Back
      </div>
      <div className="mt-4">
        <h1>Add Template</h1>
      </div>
      <div className="row mt-4">
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-12">
              <label className="labelsRequire">Name</label>
              <div>
                <InputText
                  className="w-100"
                  placeholder="User Details (Summary)"
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <label className="labelsRequire">Max Upload</label>
              <div>
                <InputText
                  className="w-100 mt-2"
                  placeholder="Enter max. download limit"
                />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <label className="labelsRequire">Speed</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="Kbps" />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <label className="labelsRequire">Max Upload</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="Kbps" />
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <label className="labelsRequire">Speed</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="Kbps" />
              </div>
            </div>
            <div className="col-md-12 mt-5">
              <div>
                <BackGroudLight text={"Hide advanced options"} />
              </div>
            </div>
            <div className="col-md-12 mt-5">
              <label className="labelsRequire">Router Type</label>
              <div>
                <DropDown className="w-100" placeholder="Mikrotik" />
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Min Download</label>
              <div>
                <InputText
                  className="w-100 mt-2"
                  placeholder="Enter min. download limit"
                />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Speed</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="Kbps" />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Min Upload</label>
              <div>
                <InputText
                  className="w-100 mt-2"
                  placeholder="Enter min. upload limit"
                />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Speed</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="Kbps" />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Burst Rate</label>
              <div>
                <InputText className="w-100 mt-2" placeholder="0/0" />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Speed</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="Kbps" />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Burst Threshold </label>
              <div>
                <InputText className="w-100 mt-2" placeholder="0/0" />
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <label className="labelsRequire">Speed</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="Kbps" />
              </div>
            </div>
            <div className="col-md-12 mt-5">
              <label className="labelsRequire">Name</label>
              <div>
                <DropDown className="w-100 mt-2" placeholder="30/30" />
              </div>
            </div>
            <div className="col-md-12 mt-5">
              <label className="labelsRequire">Priority</label>
              <div>
                <InputText className="w-100 mt-2" placeholder="--" />
              </div>
            </div>
          </div>
        </div>
        <div className=" col-md-12 flex end mt-5">
          <div className="flex gap-4">
            <button className="cancel_btn">Cancel</button>
            <button className="btn_primary_btn" onClick={()=>navigate('/uploadBandwidth')}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBandWidth;
