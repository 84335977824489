import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import UserInfo from "./UserInfo";
import "./user.css";
import AccountInfo from "./AccountInfo";
import AddUserInfo from "./AddUserInfo";

export default function AddUser({ visible, setVisible }) {
  // const [visible, setVisible] = useState(false);

  return (
    <div className="flex justify-content-center add_user_section">
      {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
      <Dialog
        header="Add New user"
        visible={visible}
        style={{ width: "50vw", padding: "1rem ", background: "white" }}
        onHide={() => setVisible(false)}
      >
        <div className="jadoo">
          <TabView>
            <TabPanel header="Account Info">
              <AccountInfo />
            </TabPanel>
            <TabPanel header="User Info">
              <AddUserInfo />
            </TabPanel>
          </TabView>
        </div>
      </Dialog>
    </div>
  );
}
