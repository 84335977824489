import React, { useState } from "react";
import DropDown from "../../../components/Inputs/DropDown";
import CreateButton from "../../../components/Buttons/CreateButton";
import SearchInput from "../../../components/Inputs/SearchInput";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../../components/Tables/BorderedTable";
import { useNavigate } from "react-router";
import { IpAddresss } from "../../../DummyData/DummyData";
import { RiEditBoxLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { Dialog } from "primereact/dialog";
import SelectDropDown from "../../../components/Inputs/SelectDropDown";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";

const IpAddress = () => {
  
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [checked, setChecked] = useState(true);
  const Ip = [
    { name: "Softnet (103.161.53.211)" },
    { name: "Softnet (103.161.53.212)" },
    { name: "Softnet (103.161.53.213)" },
    { name: "Softnet (103.161.53.214)" },
    { name: "Softnet (103.161.53.215)" },
  ];
  const [visible, setVisible] = useState(false);


  return (
    <section>
    <div className="flex between ">
      <div>
        <h1>IP Address</h1>
      </div>
      <div className="flex gap-3">
        <div>
          <DropDown placeholder={"Softnet (103.161.53.218)"} option={Ip} />
        </div>
        <div>
          <CreateButton addIcon text={"Add IP"}   onClick={() => setVisible(true)} />
        </div>
      </div>
    </div>
    <div className="flex between mt-4">
      <div>
        <SearchInput placeholder={"Enter a name here to search"} />
      </div>
      <div>
        <DropDown placeholder={"All Zones"} />
      </div>
    </div>

    <div className="table_section">
      <BorderedTable>
        <div className=" mt-3">
          <div className="flex justify-content-center align-items-center mb-4 gap-2">
            {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
            {/* <label htmlFor="input-rowclick">Row Click</label> */}
          </div>
          <DataTable
            value={IpAddresss}
            selectionMode={rowClick ? null : "checkbox"}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>

            <Column field="name" header="Name"></Column>
            <Column field="macAddress" header="Address"></Column>
            <Column field="txbytes" header="Network"></Column>
            <Column field="rxbytes" header="Interface"></Column>
            

            
            <Column
              field="logOut"
              header="Logout"
              body={(rowData) => (
                <div className="flex gap-3">
                  <RiEditBoxLine size={20} />
                  <MdDelete size={20} />
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
      </BorderedTable>
    </div>




    <Dialog
    header="Add IP"
    visible={visible}
    style={{ width: "50vw" }}
    onHide={() => setVisible(false)}
  >
  
    <div className="col-md-12 mt-4">
      <label className="labels">NAS</label>
      <div className="w-100 mt-2">
        <SelectDropDown
          className="w-100"
          placeholder={"Softnet (103.161.53.218)"}
        />
      </div>
    </div>

   
    <div className="col-md-12 mt-4">
    <label className="labels">VLan Name</label>
    <div className="w-100 mt-2">
      <InputText className="w-100" placeholder={"Soft"} />
    </div>
  </div>

  <div className="col-md-12 mt-4">
      <label className="labels">Interface</label>
      <div className="w-100 mt-2">
        <SelectDropDown
          className="w-100"
          placeholder={"Select Interface"}
        />
      </div>
    </div>

    <div className="col-md-12 mt-4">
      <label className="labels">VLan ID</label>
      <div className="w-100 mt-2">
        <SelectDropDown
          className="w-100"
          placeholder={"Enter VLan ID"}
        />
      </div>
    </div>
   


        <div className=" d-flex 2 mt-4  justify-content-center">
        
        <div style={{ display: 'flex', alignItems: 'center' }} className="w-100 mt-2   ">
        <InputSwitch style={{ marginRight: '1.5rem' }} checked={checked} onChange={(e) => setChecked(e.value)} />
        <label className="labels gap-2">Enable</label>
        </div>
      </div>

    <div className="col-md-12 flex justify-content-end gap-3 mt-5">
      <button className="cancel_btn">Cancel</button>
      <button className="btn_primary_btn">Save</button>
    </div>
  </Dialog>

  </section>
  )
}

export default IpAddress
