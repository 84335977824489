import React, { useState } from "react";
import { CiRepeat } from "react-icons/ci";
import SearchInput from "../../../components/Inputs/SearchInput";
import DropDown from "../../../components/Inputs/DropDown";
import DeleteButton from "../../../components/Buttons/DeleteButton";
import BackGroudLight from "../../../components/Buttons/BackGroudLight";
import CreateButton from "../../../components/Buttons/CreateButton";
import { BorderedTable } from "../../../components/Tables/BorderedTable";
import { useNavigate } from "react-router";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Nasmana } from "../../../DummyData/DummyData";
import { RiEditBoxLine } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import SelectDropDown from "../../../components/Inputs/SelectDropDown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { CiExport } from "react-icons/ci";
import Addnas from "./Addnas";

const Nasman = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const [editvisible, seteditvisible] = useState(false);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h1>NAS</h1>
        <div className="d-flex gap-4 center export">
          <div>
            <button
              label="Show"
              className="btn_primary_btn"
              type="button"
              onClick={() => setVisible(true)}
            >
              Add New User
            </button>
          </div>

          <Dialog
            header="Add NAS"
            visible={visible}
            style={{ width: "50vw" }}
            onHide={() => setVisible(false)}
          >
            <div className="col-md-12 mt-4">
              <label className="labels">NAS Name</label>
              <div className="w-100 mt-2">
                <InputText className="w-100" placeholder={"Enter NAS Name"} />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">IP Address</label>
              <div className="w-100 mt-2">
                <SelectDropDown
                  className="w-100"
                  placeholder={"Enter IP Address"}
                />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">Default IP Address</label>
              <div className="w-100 mt-2">
                <SelectDropDown
                  className="w-100"
                  placeholder={"Enter Default IP Address"}
                />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">NAS Type</label>
              <div className="w-100 mt-2">
                <SelectDropDown
                  className="w-100"
                  placeholder={"Select NAS Type"}
                />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">NAS User</label>
              <div className="w-100 mt-2">
                <SelectDropDown className="w-100" placeholder={"Inifity"} />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">NAS Port</label>
              <div className="w-100 mt-2">
                <SelectDropDown className="w-100" placeholder={"8228"} />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">Password</label>
              <div className="w-100 mt-2">
                <InputText
                  className="w-100"
                  placeholder={"Enter Value"}
                  type="password"
                />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">NAS Port</label>
              <div className="w-100 mt-2">
                <SelectDropDown className="w-100" placeholder={"8228"} />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">Zone</label>
              <div className="w-100 mt-2">
                <InputText className="w-100" placeholder={"Zone"} />
              </div>
            </div>

            <div className="col-md-12 flex justify-content-end gap-3 mt-5">
              <button className="cancel_btn">Cancel</button>
              <button className="btn_primary_btn">Add</button>
            </div>
          </Dialog>

          <Dialog
            header="Edit NAS"
            visible={editvisible}
            style={{ width: "50vw" }}
            onHide={() => seteditvisible(false)}
          >
            <div className="col-md-12 mt-4">
              <label className="labels">NAS Name</label>
              <div className="w-100 mt-2">
                <InputText className="w-100" placeholder={"Softnet"} />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">IP Address</label>
              <div className="w-100 mt-2">
                <SelectDropDown
                  className="w-100"
                  placeholder={"103.161.53.218"}
                />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">Default IP Address</label>
              <div className="w-100 mt-2">
                <SelectDropDown
                  className="w-100"
                  placeholder={"103.161.53.218"}
                />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">NAS Type</label>
              <div className="w-100 mt-2">
                <SelectDropDown className="w-100" placeholder={"BNG"} />
              </div>
            </div>

            <div className="col-md-12 mt-4">
              <label className="labels">Zone</label>
              <div className="w-100 mt-2">
                <SelectDropDown className="w-100" placeholder={"All"} />
              </div>
            </div>

            <div className="col-md-12 flex justify-content-end gap-3 mt-5">
              <button className="cancel_btn">Cancel</button>
              <button className="btn_primary_btn">Save</button>
            </div>
          </Dialog>
        </div>
      </div>

      <div className="mt-4 flex between">
        <SearchInput placeholder={"Enter a mane here to search"} />
        <div className="d-flex gap-2 p-6">
          <div className={`filter_dropdown`}>
            <DropDown placeholder={"All Zones"} />
          </div>
        </div>
      </div>
      <div>
        <BorderedTable>
          <div className=" mt-5">
            <DataTable
              value={Nasmana}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column
                field=""
                header="NAS Name"
                body={(rowData) => (
                  <>
                    {" "}
                    <div className="dark_content">{rowData.nasname}</div>
                  </>
                )}
              ></Column>

              <Column
                field=""
                header="IP Address"
                body={(rowData) => (
                  <>
                    {" "}
                    <div className="dark_content">{rowData.ipAddress}</div>
                  </>
                )}
              ></Column>

              <Column
                field=""
                header="Type"
                body={(rowData) => (
                  <>
                    {" "}
                    <div className="">{rowData.type}</div>
                  </>
                )}
              ></Column>

              <Column
                field=""
                header="Zone"
                body={(rowData) => (
                  <>
                    {" "}
                    <div className="">{rowData.Zone}</div>
                  </>
                )}
              ></Column>

              <Column
                field=""
                header="Action"
                body={(rowData) => (
                  <div className="d-flex gap-2 ">
                    <RiEditBoxLine
                      size={20}
                      onClick={() => seteditvisible(true)}
                    />
                    <RiDeleteBin6Line size={20} />

                    {rowData.action}
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </div>
  );
};

export default Nasman;
