import React, { useState } from "react";
import CreateButton from "../../components/Buttons/CreateButton";
import BackGroudLight from "../../components/Buttons/BackGroudLight";
import DeleteButton from "../../components/Buttons/DeleteButton";
import { CiRepeat } from "react-icons/ci";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { DummyData, PlanData } from "../../DummyData/DummyData";
import { MdDelete } from "react-icons/md";
import { IoCopyOutline } from "react-icons/io5";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router";
import { RiEditBoxLine } from "react-icons/ri";

const BillingPlan = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);

  const navigate = useNavigate();
  return (
    <div className="plans_section">
      <div className="flex between ">
        <h1>Billing Plans</h1>
        <div className="flex gap-3 center">
          <span className="export"> Export / Import</span>

          <span className="export">
            <CiRepeat /> Replace Products
          </span>
          <DeleteButton text={"Delete"} addIcon />
          <BackGroudLight text={"Calculate Plan Rate"} />
          <CreateButton addIcon text={"Create New"} />
        </div>
      </div>

      <div>
        <BorderedTable>
          <div className=" mt-5">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              value={PlanData}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column
                style={{ width: "80%" }}
                field=""
                header="Name"
                body={(rowData) => (
                  <>
                    {" "}
                    <div className="dark_content">{rowData.name}</div>
                    <div>{rowData.planRate}</div>
                  </>
                )}
              ></Column>
              <Column
                field=""
                header="Action"
                body={(rowData) => (
                  <div className="flex gap-2">
                    <RiEditBoxLine size={20} />
                    {/* <IoCopyOutline size={20} /> */}
                    {rowData.action}
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </div>
  );
};

export default BillingPlan;
