import React, { useState } from 'react'
import { TabPanel, TabView } from "primereact/tabview";
import InventoryManagement from './InventoryManagement';
import VendorManagement from './VendorManagement';
import ManageLocation from './ManageLocation';


const Inventory = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className='Inventory_section'>
            <div className="">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Item">
            <InventoryManagement/>
          
          </TabPanel>
          <TabPanel header="Vendor">
            <VendorManagement/>

          </TabPanel>
          <TabPanel header="Location">
            <ManageLocation/>
           
            </TabPanel>
        </TabView>
      </div>
       
    </div>
  )
}

export default Inventory
