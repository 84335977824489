import React, { useState } from "react";
import CreateButton from "../../components/Buttons/CreateButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { useNavigate } from "react-router";
import { ManageLocationData } from "../../DummyData/DummyData";
import { CiSettings } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";

const ManageLocation = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="VendorManagement_section mt-3">
      <div className="flex between center">
        <h2>Manage Locations</h2>
        <div className="flex gap-3 center">
          <span className="export">Import</span>
          <span className="line"></span>
          <CreateButton addIcon text={"Create New"} />
        </div>
      </div>

      <div className="table_section">
        <BorderedTable>
          <div className=" mt-3">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              value={ManageLocationData}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>
              <Column field="name" header="Name"></Column>
              <Column field="code" header="Code"></Column>
              <Column field="location" header="Location" body={(rowData)=>(
                <div><CiLocationOn size={20}/></div>
              )}></Column>
              <Column field="email" header="Email"></Column>
              <Column field="phone" header="Phone"></Column>
              <Column field="landLine" header="Landline"></Column>
              <Column field="created" header="Created"></Column>
              <Column
                field="action"
                header="Action"
                body={(rowData) => (
                  <div className="flex gap-2">
                    <CiSettings size={20} />
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </div>
  );
};

export default ManageLocation;
