import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { uploadBandwidth } from "../../DummyData/DummyData";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import BackGroudLight from "../../components/Buttons/BackGroudLight";
import { Checkbox } from "primereact/checkbox";
import { CiCircleInfo } from "react-icons/ci";

const UploadFile = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();
  return (
    <div className="UploadBandwidth_section">
      <div
        style={{ color: "#0E1073", fontWeight: "600" }}
        onClick={() => navigate("/bandwidth")}
        className="flex center gap-1 pointer"
      >
        <FaArrowLeft /> Back
      </div>
      <div className="mt-4">
        <h1>Upload File</h1>
      </div>
      <div className="dark_content mt-4">
        Upload a CSV in the following format -
      </div>

      <div className="table_section">
        <BorderedTable>
          <div className=" mt-3">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              value={uploadBandwidth}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column field="fieldName" header="Field Name"></Column>
              <Column
                field="mandatory"
                header="Mandatory?"
                className="dark_content"
              ></Column>
              <Column
                field="comments"
                header="Comments"
                className="dark_content"
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
      <div className="mt-3">
        <BackGroudLight addIcon text={"Select File"} />
      </div>
      <div className="mt-3 dark_content">
        Looks like you already have some Bandwidths.
      </div>
      <div className="mt-5 flex gap-3">
        <Checkbox
          onChange={(e) => setChecked(e.checked)}
          checked={checked}
        ></Checkbox>
        <span className="dark_content">Auto-update</span>
        <span className="">
          <CiCircleInfo />
        </span>
      </div>
      <div className="mt-3 dark_content">
      The first line of the CSV file should be the header with the field names.
      </div>
      <div className="mt-5 dark_content">
      See a <span className="theme_color"> Sample CSV File</span>
      </div>
      <div className="flex end ">
        <div className="flex gap-3">
            <button className="cancel_btn">Canel</button>
            <button className="btn_primary_btn">Import</button>

        </div>


      </div>
    </div>
  );
};

export default UploadFile;
