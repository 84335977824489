import { InputSwitch } from 'primereact/inputswitch'
import React, { useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import { useNavigate } from 'react-router'

const AssignLead = () => {
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);

  return (
    <div className='AssignLead_section'>
        <div style={{ color: "#0E1073" ,fontWeight: "600"}} onClick={()=>navigate("/leads")} className="flex center gap-1 pointer">
          <FaArrowLeft /> Back
        </div>
        <div className='mt-4'>
            <h1>Assign Leads</h1>
        </div>
        <div className='light_content mt-4'>
        Automatic Lead Assignment
        </div>
        <div className='mt-4'>
            <label className='dark_content'>Leads</label>
            <div className='mt-3 w-25' style={{background: "#F4F4F4" , color:'#6E7079', padding:"10px", borderRadius:"5px"}}>All Lead Stages</div>
        </div>
        <div className='mt-4'>
            <span className='dark_content'>Admin View Leads</span>
            <div className='mt-3 flex gap-5 center'>
                <h6>Admin can view only the leads assigned to him/her</h6>
                <span> <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} /></span>
            </div>
        </div>

        <div className='mt-4'>
            <span className='dark_content'>Admin View Created Leads</span>
            <div className='mt-3 flex gap-5 center'>
                <h6>Admin can view only the leads assigned to him/her</h6>
                <span> <InputSwitch checked={checked2} onChange={(e) => setChecked2(e.value)} /></span>
            </div>
        </div>
      
    </div>
  )
}

export default AssignLead
