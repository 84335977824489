import React, { useState } from "react";
import Calender from "../../components/Common/Calender";
import { FaArrowLeft } from "react-icons/fa6";
import { Navigate, useNavigate } from "react-router";

const SmsLogs = () => {

    const [date , setDate]= useState(new Date())
    const navigate = useNavigate()
  return (
    <div className="SmsLogs_section">
         <div style={{ color: "#0E1073" ,fontWeight: "600"}} onClick={()=>navigate("/logs")} className="flex center gap-1 pointer">
          <FaArrowLeft /> Back
        </div>
      <div className="mt-3">
        <h1>SMS Logs</h1>
      </div>
      <div className="flex between center mt-4">
        <span className="dark_content"> Murthy.M</span>
        <div className="flex gap-3 sms_calendar">
          <div>
            <Calender placeholder={"From"} date={date} setDate={setDate} />
          </div>
          <div>
            <Calender placeholder={"To"} date={date} setDate={setDate} />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <span className="dark_content">Today</span>
      </div>
      <div className="row">
        <div className="col-md-12 border rounded mt-3">
          <div className="flex between center p-3">
            <div className="flex flex-column gap-2">
              <span className="dark_content">Usage Limit</span>
              <p className="labels">
                We regret to inform you that your current internet usage has
                exceeded the allocated limit for this billing cycle.
              </p>
            </div>
            <div>34 mins ago</div>
          </div>
        </div>

        <div className="col-md-12 border rounded mt-3">
          <div className="flex between center p-3">
            <div className="flex flex-column gap-2">
              <span className="dark_content">Usage Limit</span>
              <p className="labels">
                We regret to inform you that your current internet usage has
                exceeded the allocated limit for this billing cycle.
              </p>
            </div>
            <div className="light_heading">34 mins ago</div>
          </div>
        </div>

        <div className="col-md-12 border rounded mt-3">
          <div className="flex between center p-3">
            <div className="flex flex-column gap-2">
              <span className="dark_content">Usage Limit</span>
              <p className="labels">
                We regret to inform you that your current internet usage has
                exceeded the allocated limit for this billing cycle.
              </p>
            </div>
            <div>34 mins ago</div>
          </div>
        </div>

        {/* Yesterday */}
        <div className="mt-5">
          <span className="dark_content">Yesterday</span>
        </div>
        <div className="col-md-12 border rounded mt-3">
          <div className="flex between center p-3">
            <div className="flex flex-column gap-2">
              <span className="dark_content">Usage Limit</span>
              <p className="labels">
                We regret to inform you that your current internet usage has
                exceeded the allocated limit for this billing cycle.
              </p>
            </div>
            <div>05:57 P.M.</div>
          </div>
        </div>

        <div className="col-md-12 border rounded mt-3">
          <div className="flex between center p-3">
            <div className="flex flex-column gap-2">
              <span className="dark_content">Usage Limit</span>
              <p className="labels">
                We regret to inform you that your current internet usage has
                exceeded the allocated limit for this billing cycle.
              </p>
            </div>
            <div>05:57 P.M.</div>
          </div>
        </div>
        {/* // */}

        {/* 13 feb */}
        <div className="mt-5">
          <span className="dark_content">13 February, 2024</span>
        </div>
        <div className="col-md-12 border rounded mt-3">
          <div className="flex between center p-3">
            <div className="flex flex-column gap-2">
              <span className="dark_content">Usage Limit</span>
              <p className="labels">
                We regret to inform you that your current internet usage has
                exceeded the allocated limit for this billing cycle.
              </p>
            </div>
            <div>05:57 P.M.</div>
          </div>
        </div>

        <div className="col-md-12 border rounded mt-3">
          <div className="flex between center p-3">
            <div className="flex flex-column gap-2">
              <span className="dark_content">Usage Limit</span>
              <p className="labels">
                We regret to inform you that your current internet usage has
                exceeded the allocated limit for this billing cycle.
              </p>
            </div>
            <div>05:57 P.M.</div>
          </div>
        </div>
        {/* // */}
      </div>
    </div>
  );
};

export default SmsLogs;
