import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
const Billing = () => {
  const [checked, setChecked] = useState(false);

  return (
    <div>
      <div className="col-md-12 mt-4">
        <span className="">
          <h2>Enable Notification</h2>
        </span>
      </div>

      <div>
        {" "}
        <div class="d-flex flex-row mt-3 " style={{ gap: "13rem" }}>
          <div class="d-flex p-2 gap-2">
            <Checkbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></Checkbox>
            <span className="dark_content">Enable user Notification</span>
          </div>
        </div>{" "}
      </div>

      <div className="col-md-12 mt-4">
        <span className="">
          <h2>Notification Via</h2>
        </span>
      </div>

      <div class="d-flex   mt-3" style={{ gap: "13.1rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">E-mail </span>
        </div>
        <div class="d-flex p-2 gap-3 ">
          {" "}
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">Whatapp</span>
        </div>
      </div>
      <div class="d-flex flex-row " style={{ gap: "14rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">SMS </span>
        </div>
        <div class="d-flex p-2 gap-3 ">
          {" "}
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">Web / Android</span>
        </div>
      </div>

      <div className="col-md-12 mt-4">
        <span className="">
          <h2>When to notify</h2>
        </span>
      </div>
      <div class="d-flex flex-row mt-3 " style={{ gap: "6.7rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">After billing cycle, send invoice e-mail </span>
        </div>
        <div class="d-flex p-2 gap-3 ">
          {" "}
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">After expiry, send sms</span>
        </div>
      </div>

      <div class="d-flex flex-row " style={{ gap: "9rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">After payment, send confimation </span>
        </div>
        <div class="d-flex p-2 gap-3 ">
          {" "}
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">Don't send invoice</span>
        </div>
      </div>

      <div className="col-md-12 mt-4 gap-6">
        <span className="">
          <h2>Multiple Invoice</h2>
        </span>
      </div>

      <div class="d-flex flex-row mt-3 " style={{ gap: "13rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">After billing cycle,send invoice e-mail</span>
        </div>
      </div>

      <div className="col-md-12 mt-4">
        <span className="">
          <h2>Suspend User</h2>
        </span>
      </div>
      <div class="d-flex flex-row mt-3 " style={{ gap: "13rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">
            Suspend users who don’t have active orders (Applicable only for
            pre-paid and post-paid users)
          </span>
        </div>
      </div>

      <div class="d-flex flex-row " style={{ gap: "13rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">Suspend users who don't have orders</span>
        </div>
      </div>
    </div>
  );
};

export default Billing;
