import React, { useState } from "react";
import { FaRegUser, FaLock } from "react-icons/fa6";
import logo from "../Login/img/logo.png";

import "./login.css";
import { useNavigate } from "react-router";

const Login = () => {
    const navigate =useNavigate()
    return (
        <div className="login">
            <div className="wrapper">
                <form action="">
                    <div className="wrapper_1  gap-3 flex flex-column">
                        <img src={logo} alt="Logo" className="logooo" />
                        <h1 className="text-center">Login to Account</h1>

                        <div className="logotest">
                            <p className="sub_para">Please enter your email and password to continue </p>
                        </div>
                    </div>

                    <div className="wrapper_2">

                        <div className="input-box">
                            <label for="fname" >Email address:</label>
                            <input type="text" placeholder="Sumitverma@gmail.com" required className="mt-2" />
                            

                        </div>
{/* <div><br></br><br></br></div> */}

                        <div className="input-box mt-5">
                            <div className="d-flex-class pt-2">
                                <label for="fname">Password </label><a href="#" className="fname"> {" "}Forget Password?</a>
                            </div>
                            <input type="password" placeholder="Password" required  className="mt-2" />
                        </div>
                      

                        <div className="remember-forgot " style={{ marginTop: "50px" }}>
                            <label className="flex center">
                                <input type="checkbox" />
                                Remember me
                            </label>
                            <a href="#">Forgot password</a>
                        </div>
                    </div>
                    <button type="submit" onClick={()=>navigate("/users")}>Sign In</button>
                   <div> <br></br></div>
                    <div className="register-link">
                        <p className="">
                            Don't have an account?{" "}
                            <a className="linkme" href="#">
                                Create Account
                            </a>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
