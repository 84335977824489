import React, { useState } from "react";
import SearchInput from "../../components/Inputs/SearchInput";
import DropDown from "../../components/Inputs/DropDown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { useNavigate } from "react-router";
import { CompleteLeadsData } from "../../DummyData/DummyData";
import { IoCopyOutline } from "react-icons/io5";
import Pagination from "../../components/Common/Pagination";

const CompleteLeads = () => {
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  return (
    <div className="complete_leads_section mt-3">
      <div>
        <h2>Completed Leads</h2>
      </div>
      <div className="flex between center mt-3 ">
        <div>
          <SearchInput />
        </div>
        <div>
          <DropDown placeholder={"All Zones"} />
        </div>
      </div>
      <div className="table_section">
        <BorderedTable>
          <div className=" mt-3">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              //  paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
              //  totalRecords={44}
              value={CompleteLeadsData}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column
                field=""
                header="Name"
                body={(rowData) => (
                  <div className="flex gap-2">
                    <span>{rowData.name}</span>
                    <span>
                      <IoCopyOutline />
                    </span>
                  </div>
                )}
              ></Column>
              <Column field="leadNumber" header="Lead Number"></Column>
              <Column
                field="userName"
                header="Username"
                body={(rowData) => (
                  <div className="dark_content">{rowData.userName}</div>
                )}
              ></Column>
              <Column field="group" header="Group"></Column>
              <Column field="emailId" header="Email Id"></Column>
              <Column field="phone" header="Phone"></Column>
              <Column
                field=""
                header="Zone Name"
                body={(rowData) => (
                  <div>
                    <div>{rowData.zoneName.split(" ")[0]}</div>
                    <div style={{ marginTop: "2px" }}>
                      {rowData.zoneName.split(" ")[1]}
                    </div>
                    {/* You can adjust the marginTop value as needed */}
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
        <div className="flex end">
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default CompleteLeads;
