import React from 'react'
import "./common.css"
import { Calendar } from 'primereact/calendar';
import { CiCalendar } from "react-icons/ci";

const Calender = ({date ,setDate, placeholder, className, ...props}) => {
  return (
    <div className=" flex justify-content-center w-100 position-relative calender_box">
    <Calendar {...props} value={date} onChange={(e) => setDate(e.value)} className={`w-100 custom-calendar ${className}`} placeholder={placeholder} />
    <div className='calender_icon'>
    <CiCalendar style={{color : "#0E1073"}}/>
    </div>
</div>
  )
}

export default Calender
