import React, { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import InProgress from "./InProgress";
import CompleteLeads from "./CompleteLeads";
import DropLeads from "./DropLeads";

const Leads = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="Leads_Configuration_section">
      <div>
        <h1>Leads Configuration</h1>
      </div>
      <div className="mt-3">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="In-Progress">
            <InProgress />
          </TabPanel>
          <TabPanel header="Completed">
            <CompleteLeads/>
          </TabPanel>
          <TabPanel header="Dropped">
            <DropLeads />
            </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Leads;
