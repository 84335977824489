import React from 'react'
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

const Pagination = () => {
  return (
    <div className='pagination'>
         <div className="flex justify-content-end mt-4">
          <div className="flex gap-3">
            <select className="pages_dropdown">
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
            <span className="pages">of 44 pages</span>
            <div className="arrow_bck">
              <IoIosArrowBack />
            </div>
            <div className="arrow_bck">
              <IoIosArrowForward />
            </div>
          </div>
        </div>
      
    </div>
  )
}

export default Pagination
