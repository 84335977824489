import React, { useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa6'
import { TabPanel, TabView } from "primereact/tabview";
import { useNavigate } from 'react-router';
import NewLeads01 from './NewLeads01';


const NewLead = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate()

  return (
    <div className='NewLead_section'>
         <div style={{ color: "#0E1073" ,fontWeight: "600"}} onClick={()=>navigate("/leads")} className="flex center gap-1 pointer">
          <FaArrowLeft /> Back
        </div>
        <div className="">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="01 New Leads">
            <NewLeads01/>
          </TabPanel>
          <TabPanel header="02 feasibility Check"></TabPanel>
          <TabPanel header="03 Transfer Lead">
            {/* <Sms /> */}
          </TabPanel>
          <TabPanel header="04 CAF Collection"></TabPanel>
          <TabPanel header="05 CAF Collection"></TabPanel>
        </TabView>
      </div>
      
    </div>
  )
}

export default NewLead
