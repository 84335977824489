import React, { useState } from 'react'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import SearchInput from '../../components/Inputs/SearchInput'
import CreateButton from '../../components/Buttons/CreateButton'
import { useNavigate } from 'react-router'
import { BandWidthData } from '../../DummyData/DummyData';

const Bandwidth = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate()
  return (
    <div className='bandWidth_section'>
      <div>
        <h1>Bandwidth</h1>
      </div>
      <div className='flex between center mt-4 '>
        <SearchInput/>
        <CreateButton text={'Add Template'} onClick={() => navigate('/addBandwidth')}/>

      </div>
      {/* <div className="table_section"> */}
        <BorderedTable>
          <div className=" mt-3">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              value={BandWidthData}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column field="name" header="Name"></Column>
              <Column field="maxDownload" header="Max Download"></Column>
              <Column field="maxUpload" header="Max Upload"></Column>
              <Column field="routerType" header="Router Type"></Column>
              <Column field="burstRate" header="Burst Rate"></Column>
              <Column field="burstThreshHold" header="Burst Threshold"></Column>
              <Column field="burstTime" header="Burst Time"></Column>
              <Column field="priority" header="Priority"></Column>

            </DataTable>
          </div>
        </BorderedTable>
      {/* </div> */}
    </div>
  )
}

export default Bandwidth
