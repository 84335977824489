import React from 'react'
import style from "./button.module.css"
import { IoMdAdd } from "react-icons/io";
import { MdOutlineAdd } from "react-icons/md";

const CreateButton = ({ className, text, addIcon, ...props }) => {
  return (
   
    <button {...props} className={`${className} ${style.primary_button_con} d-flex align-items-center gap-1`}>
    {addIcon ? <span className='mb-1'>
        <MdOutlineAdd className='fs-6' />
    </span> : null}
    {text}
</button>
      

  )
}

export default CreateButton
