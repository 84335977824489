import React, { useState } from "react";
import SelectDropDown from "../../components/Inputs/SelectDropDown";
import Calender from "../../components/Common/Calender";

const Reports = () => {
  const [fromDate , setFromDate] = useState()
  const [toDate , setToDate] = useState()

  return (
    <div>
      <div className="flex between">
        <h1>Reports</h1>
      </div>

      <div className="col-md-4 mt-4">
        <label className="labelsRequire">Report Type</label>
        <div className="w-100 mt-2">
          <SelectDropDown placeholder={"User Details (Summary)"} />
        </div>
      </div>

      <div className="col-md-12 mt-4">
        <span className="light_heading mt-4">Report Parameters:</span>
        <div className="row  mt-3 ">
          <div className="col-md-4">
            <label className="labelsRequire">Status</label>
            <SelectDropDown placeholder={"All"}/>
          </div>
          <div className="col-md-4">
            <label className="labelsRequire">Zone</label>
            <SelectDropDown placeholder={"Delhi Region"} />
          </div>
        </div>
      </div>

      <div className="col-md-4 mt-4">
        <label className="labelsRequire">Users Based On</label>
        <div className="w-100 ">
          <SelectDropDown placeholder={"Created Date"} />
        </div>
      </div>

      <div className="col-md-4 mt-4">
        <span className="mt-2 ">From - To</span>
        <div className="flex gap-3 sms_calendar ">
          <div>
            <Calender placeholder={"From"} date={fromDate} setDate={setFromDate} />
          </div>
          <div>
            <Calender placeholder={"To"} date={toDate} setDate={setToDate}  />
          </div>
        </div>
      </div>

      <div className="col-md-3 mt-5">
        <div className="flex justify-content-start  mt-6">
          <button className="btn_primary_btn">View Report</button>
        </div>
      </div>



      
    </div>
  );
};

export default Reports;
