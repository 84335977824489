import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { TabView, TabPanel } from "primereact/tabview";
import UserInfo from "./UserInfo";
import Policy from "./Policy";
import Recharge from "./Recharge";
import { useNavigate } from "react-router";
import AccountInfo from "./AccountInfo";


const UserProfile = () => {

  const navigate =useNavigate()
  return (
    <>
      <div className="user_profile_section">
        <div style={{ color: "#0E1073" , fontWeight: "600"}} onClick={()=>navigate("/users")} className="flex center gap-1 pointer">
          <FaArrowLeft /> Back
        </div>
        <div className="mt-3 flex between center">
          <h3>Arav Kapoor</h3>
          <div>
            <button className="delete_btn">Delete</button>
          </div>
        </div>

        <div className="">
          <TabView>
            <TabPanel header="Account Info">
            <AccountInfo/>
            </TabPanel>
           
            <TabPanel header="User Info">
              <UserInfo />
            </TabPanel>
            <TabPanel header="Policy">
              <Policy/>
            </TabPanel>
            <TabPanel header="ONU"></TabPanel>
            <TabPanel header="Recharges">
              <Recharge/>
            </TabPanel>
            <TabPanel header="Connection Attempt"></TabPanel>

          </TabView>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
