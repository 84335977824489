import React, { useState } from "react";
import CreateButton from "../../components/Buttons/CreateButton";
import BackGroudLight from "../../components/Buttons/BackGroudLight";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { useNavigate } from "react-router";
import { LeadsInprogressData } from "../../DummyData/DummyData";
import CustomAccordion from "../../components/Common/CustomAccordion";
import { HiMinusSm } from "react-icons/hi";

const InProgress = () => {
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  return (
    <div className="leads_Inprogress_section mt-3">
      <div className="flex between center">
        <h2>Leads In-Progress</h2>

        <div className="flex gap-2 center">
          <BackGroudLight
            text={"Assign Leads"}
            onClick={() => navigate("/assignLead")}
          />
          <div className="line ms-2"></div>
          <CreateButton
            addIcon
            text={"New Lead"}
            onClick={() => navigate("/newlead")}
          />
        </div>
      </div>

      <div className="table_section">
        <BorderedTable>
          <div className=" mt-3">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              value={LeadsInprogressData}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column field="phases" header="Phases"></Column>
              <Column
                field=""
                header="Admin Classification Group"
                body={(rowData) => (
                  <div className="">
                    <CustomAccordion head={"Admin"} Icon>
                      <div className="flex flex-column gap-2">
                        <div className="flex between">
                          <div> Marketing</div>
                          <div className="icon_minus">
                            <HiMinusSm />
                          </div>
                        </div>

                        <div className="flex between">
                          <div> Billing</div>
                          <div className="icon_minus">
                            <HiMinusSm />
                          </div>
                        </div>
                      </div>
                    </CustomAccordion>
                  </div>
                )}
              ></Column>
              <Column
                field=""
                header="Email Notifications"
                body={(rowData) => (
                  <div className="theme_color">{rowData.emailNotification}</div>
                )}
              ></Column>
              <Column
                field=""
                header="SMS Notifications"
                body={(rowData) => (
                  <div className="theme_color">{rowData.smsNotification}</div>
                )}
              ></Column>
              <Column
                field="enable"
                header="Enable/Disable"
                body={(rowData) => (
                  <div className="theme_color">{rowData.enable}</div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </div>
  );
};

export default InProgress;
