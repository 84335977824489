import React from 'react'
import { VscTrash } from "react-icons/vsc";
import style from "./button.module.css"


const DeleteButton = ({text , addIcon , className,...props}) => {
  return (
   <button {...props}  className={`${className} ${style.delete_button} d-flex align-items-center gap-1`}>
    {addIcon ? <span className='mb-1'>
        <VscTrash className='fs-6' />
    </span> : null}
    {text}

   </button>
  )
}

export default DeleteButton
