import { RadioButton } from "primereact/radiobutton";
import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import Calender from "../../components/Common/Calender";
import SelectDropDown from "../../components/Inputs/SelectDropDown";
import { InputText } from "primereact/inputtext";
import DropDown from "../../components/Inputs/DropDown";

const Policy = () => {
  const [ingredient, setIngredient] = useState("");
  const [checked, setChecked] = useState(false);
  const [date, setDate] = useState(null);
  return (
    <div className="policy_section">
      <div className="flex between">
        <h4>Policy</h4>
        <div className={`user_setting`}>
            <DropDown placeholder={"User Setting"} />
          </div>
      </div>
      <div className="row">
        <div className="col-md-10 ">
          <div className="row">
            <div className="col-md-12">
              <span className="light_heading">Invoice</span>
              <div className="flex gap-5">
                <div className="flex gap-4 mt-3">
                  <Checkbox
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                  ></Checkbox>
                  <label className="labels">Auto Invoice Generate</label>
                </div>
                <div className="flex gap-4 mt-3">
                  <Checkbox
                    onChange={(e) => setChecked(e.checked)}
                    checked={checked}
                  ></Checkbox>
                  <label className="labels">Auto Recharge Renew</label>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-5">
              <span className="light_heading">Grace Period</span>
              <div className="flex gap-4 mt-3">
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                ></Checkbox>
                <label className="labels">Grace Period</label>
              </div>
            </div>
            <div className="col-md-5 mt-4 ">
              <Calender className="w-100" date={date} setDate={setDate} placeholder={'DD/YY/MM'} />
            </div>
            <div className="col-md-5 mt-4 "></div>
            <div className="col-md-12 mt-4">
              <span className="light_heading">Location</span>
            </div>
            <div className="col-md-5 mt-3 ">
              <label className="labelsRequire">Address List</label>
              <div className="mt-2">
                <SelectDropDown placeholder={"Add or Select Address"} />
              </div>
            </div>
            <div className="col-md-5 mt-3">
              <label className="labelsRequire">Zone</label>
              <div className="mt-2">
                <SelectDropDown placeholder={"Input Zone"} />
              </div>
            </div>

            {/* Discount */}
            <div className="col-md-12 mt-5">
              <span className="light_heading">Discount</span>
            </div>
            <div className="col-md-5 mt-3 ">
              <label className="labelsRequire">Type</label>
              <div className="mt-2">
                <SelectDropDown placeholder={"Select Percentage or Rupees"} />
              </div>
            </div>
            <div className="col-md-5 mt-3">
              <label className="labelsRequire">Value</label>
              <div className="mt-2">
                <InputText className="w-100" placeholder="Enter Value" />
              </div>
            </div>
            {/* // */}

            {/* Bandwith */}
            <div className="col-md-12 mt-5">
              <span className="light_heading">Bandwidth</span>
            </div>
            <div className="col-md-5 mt-3">
              <label className="labelsRequire">Max Download</label>
              <div className="mt-2">
                <InputText className="w-100" placeholder="Enter value" />
              </div>
            </div>
            <div className="col-md-5 mt-3 ">
              <label className="labelsRequire">Speed</label>
              <div className="mt-2">
                <SelectDropDown placeholder={"Select Percentage or Rupees"} />
              </div>
            </div>
            <div className="col-md-5 mt-3">
              <label className="labelsRequire">Max Upload</label>
              <div className="mt-2">
                <InputText className="w-100" placeholder="Enter value" />
              </div>
            </div>
            <div className="col-md-5 mt-3 ">
              <label className="labelsRequire">Speed</label>
              <div className="mt-2">
                <SelectDropDown placeholder={"Mbps"} />
              </div>
            </div>
            {/* // */}

            {/* Expiration */}
            <div className="col-md-12 mt-5">
              <span className="light_heading">Plan Expiration Date</span>
            </div>

            <div className="col-md-10 mt-3 ">
              <label className="labelsRequire">Exp. Date</label>
              <div className="mt-2">
                <Calender
                  placeholder={"Select Dates"}
                  date={date}
                  setDate={setDate}
                />
              </div>
            </div>
            {/* // */}

            {/* Nass */}
            <div className="col-md-12 mt-5">
              <span className="light_heading">Nas</span>
            </div>

            <div className="col-md-10 mt-3 ">
              <label className="labelsRequire">Nas</label>
              <div className="mt-2">
                <SelectDropDown placeholder={"Select Nas"} />
              </div>
            </div>
            {/* // */}

            {/* ip address */}
            <div className="col-md-12 mt-5">
              <span className="light_heading">IP Address</span>
            </div>

            <div className="col-md-9 mt-3 ">
              <label className="labelsRequire">IP Address</label>
              <div className="mt-2">
                <SelectDropDown />
              </div>
            </div>
            <div className="col-md-3 mt-5 ">
              <button className="unused">Unused Ip</button>
            </div>
          </div>

          {/* // */}

          {/* Simultaneous */}
          <div className="col-md-12 mt-5">
            <span className="light_heading">Simultaneous Use</span>
          </div>

          <div className="col-md-10 mt-3 ">
            <label className="labelsRequire">Simultaneous Use *</label>
            <div className="mt-2">
              <InputText placeholder="Enter Value" className="w-100" />
            </div>
          </div>
          {/* // */}

          {/* plan group */}
          <div className="col-md-12 mt-5">
            <span className="light_heading">Plan Group</span>
          </div>

          <div className="col-md-10 mt-3 ">
            <label className="labelsRequire">Plan Group</label>
            <div className="mt-2">
              <SelectDropDown placeholder={"Select Plan Group"} />
            </div>
          </div>

          {/* // */}

          {/* Extra Attribute */}

          <div className="col-md-5 mt-5 flex gap-5 ">
            <div className="flex flex-column gap-2">
              <div className="plus">+</div>
              <div className="minus">-</div>
              <div className="minus">-</div>
            </div>
            <div className="flex flex-column gap-3">
              <label className="labels">Attributes</label>
              <div>
                <DropDown placeholder={"Select Attribute"} />
              </div>
              <div>
                <DropDown placeholder={"Select Attribute"} />
              </div>
            </div>

            <div className="flex flex-column gap-3">
              <label className="labels">Value</label>
              <div>
                <InputText placeholder={"Enter value"} />
              </div>
              <div>
                <InputText placeholder={"Enter value"}/>
              </div>
            </div>
          </div>
          {/* // */}
        </div>
      </div>
      <div className="flex justify-content-end">
        <button className="btn_primary_btn">Apply</button>
      </div>
    </div>
  );
};

export default Policy;
