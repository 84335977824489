import React from "react";
import { useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { TreeSelect } from "primereact/treeselect";
import SelectDropDown from "../../components/Inputs/SelectDropDown";
import { InputText } from "primereact/inputtext";
import InputUpload from "../../components/Inputs/InputUpload";

const AddUserInfo = () => {
    const [ingredient, setIngredient] = useState("");
    const [checked, setChecked] = useState(false);
  return (
    <div className="account_info">

  

    <div className="col-md-12 mt-3">
      <span className="light_heading">Personal Detail</span>
      <div className="row mt-3 ">
        <div className="col-md-6">
          <label className="labelsRequire">First Name</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="User Details(Summary)"
          />
        </div>
        <div className="col-md-6">
          <label className="labelsRequire">Last Name</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="User Details(Summary)"
          />
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Mobile Number</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="User Details(Summary)"
          />
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Alternate Mobile Number </label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="User Details(Summary)"
          />
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Email</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="User Details(Summary)"
          />
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Tax Number</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="User Details(Summary)"
          />
        </div>
        {/* biiling */}

        <div className="col-md-12 mt-5">
          <span className="light_heading">Billing Address</span>{" "}
        </div>
        <div className="flex gap-4 mt-3 col-md-12">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span>Use billing address as installation address</span>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Billing Address Line 1</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Enter billing address"
          />
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Billing Address Line 2</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Enter billing address"
          />
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">City</label>
          <div className="w-100 mt-2">
            <SelectDropDown placeholder={"Select City"} />
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Zip Code</label>
          <div className="w-100 mt-2">
            <SelectDropDown placeholder={"Select State"} />
          </div>
        </div>
        {/* installation */}
        <div className="col-md-12 mt-5">
          <span className="light_heading">Installation Address</span>{" "}
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Billing Address Line 1</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Enter billing address"
          />
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Billing Address Line 2</label>
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Enter billing address"
          />
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">City</label>
          <div className="w-100 mt-2">
            <SelectDropDown placeholder={"Select City"} />
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Zip Code</label>
          <div className="w-100 mt-2">
            <SelectDropDown placeholder={"Select State"} />
          </div>
        </div>

        {/* Document */}
        <div className="col-md-12 mt-5">
          <span className="light_heading">Document Details</span>{" "}
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">ID Proof</label>
          <div className="w-100 mt-2">
            <SelectDropDown placeholder={"Select State"} />
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">ID Proof No</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder="Enter Id Proof" />
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Address Proof</label>
          <div className="w-100 mt-2">
            <SelectDropDown placeholder={"Select City"} />
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Address Proof No</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder="Enter Address Proof" />
          </div>
        </div>
        {/* Portal Detail */}
        <div className="col-md-12 mt-5">
          <span className="light_heading">Portal Details</span>{" "}
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Notes</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder="Enter Id Proof" />
          </div>
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">CPE IP Address</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder="Enter Address Proof" />
          </div>
        </div>
        <div className="flex gap-4 mt-3 col-md-12">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span>Enable user portal login</span>
        </div>

        {/* Document Upload */}
        <div className="col-md-12 mt-5">
          <span className="light_heading">Document Upload</span>{" "}
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">CPE IP Address</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder="Enter Address Proof" />
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Choose File *</label>
          <div className="w-100 mt-2">
            <InputUpload placeholder={"Select file to upload"} />
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <button className="upload_btn">Upload</button>
        </div>
      </div>
    </div>
    <div className="flex justify-content-end">
      <button className="btn_primary_btn">Next</button>
    </div>
  </div>
  )
}

export default AddUserInfo
