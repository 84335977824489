
import { LiaEditSolid } from "react-icons/lia";
import { RiEditBoxLine } from "react-icons/ri";

export const DummyData =[
    {
        id : '86568',
        userName : "Aaravkapoor",
        circuitId : "222",
        firstName : "Arav ",
        lastName : "Kappor",
        action : <LiaEditSolid size={20}/>,
        status : "Active"
        
      },
      {
        id : '86062',
        userName : "baravkapoor",
        circuitId : "151",
        firstName : "Arav ",
        lastName : "Kappor",
        action : <LiaEditSolid size={20}/>,
        status : "Expire"
        
      },
      {
        id : '87561',
        userName : "caravkapoor",
        circuitId : "152",
        firstName : "Arav ",
        lastName : "Kappor",
        action : <LiaEditSolid size={20}/>,
        status : "Expire"
        
      },
      {
        id : '86368',
        userName : "Aaravkapoor",
        circuitId : "153",
        firstName : "Arav ",
        lastName : "Kappor",
        action : <LiaEditSolid size={20}/>,
        status : "Active"
        
      },
      {
        id : '46562',
        userName : "Aaravkapoor",
        circuitId : "154",
        firstName : "Arav ",
        lastName : "Kappor",
        action : <LiaEditSolid size={20}/>,
        status : "Expire"
        
      },
      {
        id : '86598',
        userName : "Aaravkapoor",
        circuitId : "155",
        firstName : "Arav ",
        lastName : "Tappor",
        action : <LiaEditSolid size={20}/>,
        status : "Active"
        
      },
      {
        id : '8659',
        userName : "Aaravkapoor",
        circuitId : "156",
        firstName : "Arav ",
        lastName : "Kappor",
        action : <LiaEditSolid size={20}/>,
        status : "Active"
        
      },
      {
        id : '86598',
        userName : "Aaravkapoor",
        circuitId : "156",
        firstName : "Arav ",
        lastName : "Kappor",
        action : <LiaEditSolid size={20}/>,
        status : "Active"
        
      },
      
]
export const RechargeData =[
  {
      id : '8658',
      planName : "20 MBPS",
      quantity : "0/1",
      amount : "$100 ",
      status : "Expired",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",

      type : "Plan",
      action : "Option"
      
    },
    {
      id : '8668',
      planName : "20 MBPS ",
      quantity : "0/1",
      amount : "$100 ",
      status : "Expired",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",
      type : "Plan",
      action : "Option"
      
    },
    {
      id : '8656',
      planName : "20 MBPS",
      quantity : "0/1",
      amount : "$100 ",
      status : "Expired",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",
      type : "Plan",
      action : "Option"
      
    },
    {
      id : '6568',
      planName : "20 MBPS ",
      quantity : "0/1",
      amount : "$100 ",
      status : "Expired",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",
      type : "Plan",
      action : "Option"
      
    },
    {
      id : '8568',
      planName : "20 MBPS",
      quantity : "0/1",
      amount : "$100 ",
      status : "Active",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",
      type : "Plan",
      action : "Option"
      
    },
    {
      id : '868',
      planName : "20 MBPS",
      quantity : "0/1",
      amount : "$100 ",
      status : "Active",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",
      type : "Plan",
      action : "Option"
      
    },
    {
      id : '88',
      planName : "20 MBPS",
      quantity : "0/1",
      amount : "$100 ",
      status : "Active",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",
      type : "Plan",
      action : "Option"
      
    },
    {
      id : '8',
      planName : "20 MBPS ",
      quantity : "0/1",
      amount : "$100 ",
      status : "Active",
      rechargeTime : "10:46 Am",
      rechargeDate : "11-03-2024",
      type : "Plan",
      action : "Option"
      
    },
    
]
export const PlanData =[
  {
    id : '1',
    name : "1 Mbps_50 GB, 512 Kbps",
    planRate : "Plan Rate: Rs 800",
    action : ''
  
  },
  {
    id : '2',
    name : "1 Mbps_50 GB, 512 Kbps",
    planRate : "Plan Rate: Rs 800",
    action : ''
  
  }, {
    id : '3',
    name : "1 Mbps_50 GB, 512 Kbps",
    planRate : "Plan Rate: Rs 800",
    action : ''
  
  }, {
    id : '4',
    name : "1 Mbps_50 GB, 512 Kbps",
    planRate : "Plan Rate: Rs 800",
    action : ''
  
  }, {
    id : '5',
    name : "1 Mbps_50 GB, 512 Kbps",
    planRate : "Plan Rate: Rs 800",
  
  }, {
    id : '6',
    name : "1 Mbps_50 GB, 512 Kbps",
    planRate : "Plan Rate: Rs 800",
     action :''
  
  }, {
    id : '7',
    name : "1 Mbps_50 GB, 512 Kbps",
    planRate : "Plan Rate: Rs 800",
  
  },
]
export const LogsData = [

  {
    userId : '865744',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Active'
  },
  {
    userId : '865743',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Active'
  },
  {
    userId : '865742',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Expired'
  },
  {
    userId : '865741',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Expired'
  },
  {
    userId : '865740',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Active'
  },
  {
    userId : '865745',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Active'
  },
  {
    userId : '865746',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Expired'
  },
  {
    userId : '865747',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Active'
  },
  {
    userId : '865748',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Expired'
  },
  {
    userId : '865749',
    userName : "Murthy.M",
    lastSentTime : "10:46 AM",
    lastSentDate : "11-03-2024",
    action : 'View',
    status :  'Active'
  },

]
export const LeadsInprogressData =[
  {
    id : '1',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },
  {
    id : '2',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },
  {
    id : '3',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },
  {
    id : '4',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },
  {
    id : '5',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },
  {
    id : '6',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },
  {
    id : '7',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },
  {
    id : '8',
    phases : "New Leads",
    adminClassificationGroup : "",
    emailNotification : "Enable Email",
    smsNotification : 'Enable SMS',
    enable :  'Enable'
  },

]
export const CompleteLeadsData =[
  {
    id : '1',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',

  },
  {
    id : '2',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',

  },
  {
    id : '3',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',

  },
  {
    id : '4',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',

  },
  {
    id : '5',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',

  },
  {
    id : '5',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',

  },

]
export const DropLeadsData =[
  {
    id : '1',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    reasonOfRejection : 'Out Of Network',
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',
    cretedBy : "10:46 AM,11-03-24",
    action : ""

  },
  {
    id : '2',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    reasonOfRejection : 'Out Of Network',
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',
    cretedBy : "10:46 AM,11-03-24",

    action : ""

  },
  {
    id : '3',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    reasonOfRejection : 'Out Of Network',
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',
    cretedBy : "10:46 AM,11-03-24",

    action : ""

  },
  {
    id : '4',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    reasonOfRejection : 'Out Of Network',
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',
    cretedBy : "10:46 AM,11-03-24",

    action : ""

  },
  {
    id : '5',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    reasonOfRejection : 'Out Of Network',
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',
    cretedBy : "10:46 AM,11-03-24",
    action : ""

  },
  {
    id : '6',
    name : "AravKapoor",
    leadNumber : "156182",
    userName : "Aaravkapoor",
    reasonOfRejection : 'Out Of Network',
    group : 'Arav',
    emailId :  'Kapoor',
    phone :  '+62981989',
    zoneName :  'Avneesh Networks',
    cretedBy : "10:46 AM,11-03-24",

    action : ""

  },

]
export const Allinvoices =[
  {
    id : '13',
    invoiceno: "ATBB/02/20/3739",
    name: "Selvaraj",
    zone: "ISP",
    balanece: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Paid",
    createtime: "10:46 AM",
    createdate: "11-03-2024",
    description: "ATB-699 period..."

    
  },
  {
    id : '2',
    invoiceno: "ATBB/02/20/3739",
    name: "Selvaraj",
    zone: "ISP",
    balanece: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Paid",
    createtime: "10:46 AM",
    createdate: "11-03-2024",
    description: "ATB-699 period..."

    
  },
  {
    id : '3',
    invoiceno: "ATBB/02/20/3739",
    name: "Selvaraj",
    zone: "ISP",
    balanece: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Paid",
    createtime: "10:46 AM",
    createdate: "11-03-2024",
    description: "ATB-699 period..."

    
  },
  {
    id : '4',
    invoiceno: "ATBB/02/20/3739",
    name: "Selvaraj",
    zone: "ISP",
    balanece: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Pending",
    createtime: "10:46 AM",
    createdate: "11-03-2024",
    description: "ATB-699 period..."

    
  },
  {
    id : '5',
    invoiceno: "ATBB/02/20/3739",
    name: "Selvaraj",
    zone: "ISP",
    balanece: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Pending",
    createtime: "10:46 AM",
    createdate: "11-03-2024",
    description: "ATB-699 period..."

    
  },
  {
    id : '6',
    invoiceno: "ATBB/02/20/3739",
    name: "Selvaraj",
    zone: "ISP",
    balanece: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Unpaid",
    createtime: "10:46 AM",
    createdate: "11-03-2024",
    description: "ATB-699 period..."


  }
]
export const WavesNet =[
  {
    id : '2',
    userName : "Aaravkapoor",
    invoiceno: "ATBB/02/20/3739",
    firstname: "Selvaraj",
    zone: "ISP",
    balance: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Unpaid",
    createtime: "10:46 AM,11-03-2024",
  },
  {
    id : '3',
    userName : "Aaravkapoor",
    invoiceno: "ATBB/02/20/3739",
    firstname: "Selvaraj",
    zone: "ISP",
    balance: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Unpaid",
    createtime: "10:46 AM,11-03-2024",
  },
  {
    id : '4',
    userName : "Aaravkapoor",
    invoiceno: "ATBB/02/20/3739",
    firstname: "Selvaraj",
    zone: "ISP",
    balance: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Unpaid",
    createtime: "10:46 AM,11-03-2024",
  },
  {
    id : '5',
    userName : "Aaravkapoor",
    invoiceno: "ATBB/02/20/3739",
    firstname: "Selvaraj",
    zone: "ISP",
    balance: "825.00",
    total: '4476035',
    invoiceid: '4476035',
    status: "Unpaid",
    createtime: "10:46 AM,11-03-2024",
  },
 
]
export const BandWidthData =[
  {
    id: '1',
    name : "Arav Kapoor",
    maxDownload : "100Mbps",
    maxUpload : '50 Mbps',
    routerType : "Mikrotik",
    burstRate : "0/0",
    burstThreshHold : "0/0",
    burstTime : "30/30",
    priority : "4"
  },
  {
    id: '2',
    name : "Arav Kapoor",
    maxDownload : "100Mbps",
    maxUpload : '50 Mbps',
    routerType : "Mikrotik",
    burstRate : "0/0",
    burstThreshHold : "0/0",
    burstTime : "30/30",
    priority : "4"
  },
  {
    id: '3',
    name : "Arav Kapoor",
    maxDownload : "100Mbps",
    maxUpload : '50 Mbps',
    routerType : "Mikrotik",
    burstRate : "0/0",
    burstThreshHold : "0/0",
    burstTime : "30/30",
    priority : "4"
  },
  {
    id: '4',
    name : "Arav Kapoor",
    maxDownload : "100Mbps",
    maxUpload : '50 Mbps',
    routerType : "Mikrotik",
    burstRate : "0/0",
    burstThreshHold : "0/0",
    burstTime : "30/30",
    priority : "4"
  },
]
export const uploadBandwidth = [
  {
    id: '1',
    fieldName : "max_upload",
    mandatory : "Yes",
    comments : 'Has to be unique across the zone.',
   
  },
  {
    id: '2',
    fieldName : "min_download",
    mandatory : "Yes",
    comments : 'Min Download speed cannot be less than 16 kbps.',
   
  }, {
    id: '3',
    fieldName : "max_upload",
    mandatory : "Yes",
    comments : 'Has to be unique across the zone.',
   
  }, {
    id: '4',
    fieldName : "max_upload",
    mandatory : "Yes",
    comments : 'Has to be unique across the zone.',
   
  }, {
    id: '5',
    fieldName : "min_download",
    mandatory : "Yes",
    comments : 'Min Download speed cannot be less than 16 kbps.',
   
  },
  {
    id: '6',
    fieldName : "burst_rate",
    mandatory : "Yes",
    comments : 'Burst Rate cannot be less than Max Upload and Max Download.',
   
  }, {
    id: '7',
    fieldName : "burst_threshold",
    mandatory : "Yes",
    comments : 'Default empty.',
   
  },
  {
    id: '8',
    fieldName : "burst_time",
    mandatory : "Yes",
    comments : 'Default empty.',
   
  }, {
    id: '9',
    fieldName : "priority",
    mandatory : "Yes",
    comments : 'Priority should be 0 to 8.',
   
  },
  {
    id: '10',
    fieldName : "routertype",
    mandatory : "Yes",
    comments : 'Router types may be mikrotik or cisco or ipacct.',
   
  },
]
export const InventoryMangemnet = [
 {
  id : "1",
  name : 'Home Router',
  action : ''
 },
 {
  id : "2",
  name : 'Router',
  action : ''
 },
]
export const ManageLocationData = [
  {
    id : "1",
   name : 'Delhi',
   code : '',
   location : '',
   email : '',
   landLine : '',
   created : '18-06-2019',
   action : ''
  },
  {
    id : "2",
    name : 'Kappa',
    code : '',
    location : '',
    email : '',
    landLine : '',
    created : '18-06-2019',
    action : ''
   },
 ]
 export const NasData = [
  {
   id : "1",
   user : 'Aarav',
   ipAddress : '192.168.111.3',
   upTime : '12:11:00',
   rxSpeed : '0B',
   txSpeed : '0B',
   rxBytes : '792.33M',
   txBytes : '792.33M',
   logOut  : ""
  },
  {
    id : "2",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },
   {
    id : "3",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },  {
    id : "4",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },  {
    id : "5",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },
   {
    id : "6",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },
   {
    id : "7",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },
   {
    id : "8",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },
   {
    id : "9",
    user : 'Aarav',
    ipAddress : '192.168.111.3',
    upTime : '12:11:00',
    rxSpeed : '0B',
    txSpeed : '0B',
    rxBytes : '792.33M',
    txBytes : '792.33M',
    logOut  : ""
   },
 ]
 export const Nasmana = [
  {
    id : '1',
    nasname : "Php3",
    ipAddress : '192.168.111.3',
    type : 'Mikrotik',
    Zone : 'All',
    action : ''

  },
  {
    id : '2',
    nasname : "Php3",
    ipAddress : '192.168.111.3',
    type : 'Mikrotik',
    Zone : 'All',
    action : ''

  },
  {
    id : '3',
    nasname : "Php3",
    ipAddress : '192.168.111.3',
    type : 'Mikrotik',
    Zone : 'All',
    action : ''

  },
  {
    id : '4',
    nasname : "Php3",
    ipAddress : '192.168.111.3',
    type : 'Mikrotik',
    Zone : 'All',
    action : ''

  },
 
 ]
 export const interfacedata = [
  {
   id : "1",
   name : 'Softnet',
   macAddress : '192.168.111.3',
   txbytes : '792.33M',
   rxbytes : '792.33M',
   txspeed : '0B',
   respeed : '0B',
   Type : 'BRG',
   Status: '792.33M',
   action : ''
  },
  {
    id : "2",
    name : 'Softnet',
    macAddress : '192.168.111.3',
    txbytes : '792.33M',
    rxbytes : '792.33M',
    txspeed : '0B',
    respeed : '0B',
    Type : 'BRG',
    Status: '792.33M',
    action : ''
   },
   {
    id : "3",
    name : 'Softnet',
    macAddress : '192.168.111.3',
    txbytes : '792.33M',
    rxbytes : '792.33M',
    txspeed : '0B',
    respeed : '0B',
    Type : 'BRG',
    Status: '792.33M',
    action : ''
   },
   {
    id : "4",
    name : 'Softnet',
    macAddress : '192.168.111.3',
    txbytes : '792.33M',
    rxbytes : '792.33M',
    txspeed : '0B',
    respeed : '0B',
    Type : 'BRG',
    Status: '792.33M',
    action : ''
   },
   {
    id : "5",
    name : 'Softnet',
    macAddress : '192.168.111.3',
    txbytes : '792.33M',
    rxbytes : '792.33M',
    txspeed : '0B',
    respeed : '0B',
    Type : 'BRG',
    Status: '792.33M',
    action : ''
   },  
 ]
 export const IpAddresss =[

  {


    id : "5",
    name : 'Softnet',
   
    
  
    action : ''
  }
 ]
 export const Firewalld =[

  {


    id : "5",
    name : 'Softnet',
   
    
  
    action : ''
  }
 ]