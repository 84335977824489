import Accordion from "react-bootstrap/Accordion";
import { GoPlus } from "react-icons/go";
import { HiMinusSm } from "react-icons/hi";

export default function CustomAccordion({head , children , Icon}) {
  return (
    <div className="accordion_section">
      <Accordion  className="custom_accordion w-75">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="icon_plus me-3">
              {Icon ? <GoPlus /> : ""}
            </div>
            {head}
          </Accordion.Header>
          <Accordion.Body className="accordion_content">
            {children}
            
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
