import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import style from "./input.module.css";

export default function DropDown({ className, placeholder,option, ...props }) {
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  return (
    <div className={`${style.dropDown}`}>
      <Dropdown
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.value)}
        options={option ? option : cities}
        optionLabel="name"
        placeholder={placeholder}
        className={`w-full md:w-14rem ${className}`}
        {...props}
      />
    </div>
  );
}
