import React, { useState } from "react";

import SearchInput from "../../components/Inputs/SearchInput";
import DropDown from "../../components/Inputs/DropDown";
import Calender from "../../components/Common/Calender";
import "./Logs.css";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { LogsData } from "../../DummyData/DummyData";
import { DataTable } from "primereact/datatable";

import { useNavigate } from "react-router";

const Sms = () => {
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const [date , setDate] = useState()
  return (
    <div className="sms_section">
      <div className="mt-4 flex between ">
        <SearchInput
          placeholder={"User ID, User Name, Circuit Id, First Name"}
        />
        <div className="flex justify-content-end gap-2 mx-2">
          <div>
            {" "}
            <DropDown placeholder={"All Zones"} />
          </div>
          <div>
            {" "}
            <DropDown placeholder={"Status"} />
          </div>
          <div className="date_calender ">
            {" "}
            <Calender placeholder={"Select Date"} setDate={setDate} date={date} />
          </div>
        </div>
      </div>

      <BorderedTable>
        <div className=" mt-5">
          <div className="flex justify-content-center align-items-center mb-4 gap-2">
            {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
            {/* <label htmlFor="input-rowclick">Row Click</label> */}
          </div>
          <DataTable
            value={LogsData}
            selectionMode={rowClick ? null : "checkbox"}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="userId"
            tableStyle={{ minWidth: "50rem" }}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>

            <Column field="userId" header="User Id"></Column>

            <Column
              field=""
              header="User Name"
              className="dark_content"
             body={(rowData)=>(
              <div  onClick={() => navigate("/smsLogs")}>{rowData.userName}</div>
             )}
            ></Column>
            <Column
              field=""
              header="Last Sent"
              body={(rowData) => (
                <>
                  {" "}
                  <div className="dark_content">{rowData.lastSentTime}</div>
                  <div>{rowData.lastSentDate}</div>
                </>
              )}
            ></Column>
            <Column
              field="action"
              header="Action"
              style={{ color: "#0E1073" }}
            ></Column>
            <Column
              header="Status"
              body={(rowData) => (
                <div className="d-flex center">
                  <div
                    className={`statusBg ${
                      rowData?.status === "Active"
                        ? "statusActive"
                        : "statusExpire"
                    }`}
                   
                  >
                    {rowData?.status}
                  </div>
                </div>
              )}
            ></Column>
          </DataTable> 
        </div>
      </BorderedTable>
    </div>
  );
};

export default Sms;
