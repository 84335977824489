import React, { useState } from "react";
import { DummyData } from "../../DummyData/DummyData";
import { TreeSelect } from "primereact/treeselect";
import { Dropdown } from "primereact/dropdown";

const SelectDropDown = ({ placeholder }, props) => {
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  return (
    <div>
      <Dropdown
        value={selectedNodeKey}
        {...props}
        onChange={(e) => setSelectedNodeKey(e.value)}
        options={cities}
        optionLabel="name"
        className=" w-100"
        placeholder={placeholder}
      ></Dropdown>
    </div>
  );
};

export default SelectDropDown;
