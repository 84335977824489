import React, { useState } from "react";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { BsFileText } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
// import myImage from "../../../public/images/logo.png";
import "primeicons/primeicons.css";
import { IoReload } from "react-icons/io5";

import { Accordion, AccordionTab } from "primereact/accordion";
import { BsBoxArrowInRight,BsBoxArrowInLeft } from "react-icons/bs";

export default function Sidebar({ children }) {
  let [sideNavShow, setSideNavShow] = useState(false);

  function handleHide() {
    setSideNavShow(!sideNavShow);
  }

  return (
    <div
      className="sidebar_con"
      style={{ gridTemplateColumns: sideNavShow ? "0px 1fr" : "240px 1fr" }}
    >
      <div className="left_con">
        <div
          className="logo_main_con"
          style={{ marginLeft: sideNavShow ? "15px" : "25px" }}
        ></div>
        <img
          className="logo_i"
          src="./images/logo.png"
          alt="My Image"
          style={{ width: "150px", height: "auto" }}
        />

        <div className="navlink_con">
          <NavLink style={{ textDecoration: "none" }} to={`/users`}>
            <div className="navlink ">
              <FiUsers />
              <div>Users</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/nas`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>NAS</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/Plans`}>
            <div className="navlink ">
              <BsFileText />
              <div>Plans</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/billingplans`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>Billing Plans</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/invoicing`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>Invoicing</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/bandwidth`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>Bandwidth</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/inventory`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>Inventory</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/reports`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>Reports</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/notification`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>Notifications</div>
            </div>
          </NavLink>

          <NavLink style={{ textDecoration: "none" }} to={`/logs`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>logs</div>
            </div>
          </NavLink>
          <NavLink style={{ textDecoration: "none" }} to={`/leads`}>
            <div className="navlink ">
              <i className="pi pi-send" />
              <div>Leads</div>
            </div>
          </NavLink>

          <div className="navlink_cn">
            <Accordion
              activeIndex={1}
              collapseIcon={<i className="pi pi-send" />}
              expandIcon={<i className="pi pi-send" />}
            >
              <AccordionTab header="Nas Management" className="">
                <NavLink
                  style={{ textDecoration: "none" }}
                  to={`/nasmanagement`}
                >
                  <div className="fw-6">
                    <div>Nas Configuration</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`/nasman`}>
                  <div className="fw-6 ">
                    <div>NAS</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`/interface`}>
                  <div className=" fw-6">
                    <div>Interface</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`/ipaddress`}>
                  <div className="fw-6 ">
                    <div>Ip Address</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`firewall`}>
                  <div className="fw-6 ">
                    <div>Firewall</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`/queue`}>
                  <div className=" fw-6">
                    <div>Queue</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`/routenas`}>
                  <div className="fw-6 ">
                    <div>Route</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`/dhcpserver`}>
                  <div className=" fw-6">
                    <div>DHCP Server</div>
                  </div>
                </NavLink>
                <NavLink style={{ textDecoration: "none" }} to={`/ippool`}>
                  <div className="fw-6 ">
                    <div>IP Pool</div>
                  </div>
                </NavLink>
              </AccordionTab>
            </Accordion>
          </div>

          <br />
          <br />

          <div className="d-flex align-items-center pt-5 flex-column gap-3 border-top">
            <NavLink style={{ textDecoration: "none" }} to={"*"}>
              <div className="navlink ">
                <div>Settings</div>
              </div>
            </NavLink>
            <NavLink style={{ textDecoration: "none" }} to={`/`}>
              <div className="navlink ">
                <div>Logout</div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="right_con">
        <div className="top_barr">
          <div className="header_buttons">
            <div className="d-flex gap-3 align-items-center">
              <span>
               {sideNavShow ?  <BsBoxArrowInRight
                  size={24}
                  style={{ cursor: "pointer",color:"#202224" }}
                  onClick={handleHide}
                /> :  <BsBoxArrowInLeft
                size={24}
                style={{ cursor: "pointer" ,color:"#202224"}}
                onClick={handleHide}
              />}
              </span>
            </div>
            <div className="flex align_center"></div>
          </div>

          <div className="con">
            <div className="header-bar_1">
              <div className="user-div">
                <div>USERS</div>
                <div>10</div>
              </div>
              <div className="online-div">
                <div>Online</div>
                <div>8</div>
              </div>
              <div>
                <IoReload fontSize={16} />
              </div>
            </div>

            <div className="header-bar_2">
              <div className="admin_icon">
                <img
                  className="logo_ii"
                  src="./images/group.png"
                  alt="Image"
                  // style={{ width: "150px", height: "auto" }}
                />
              </div>
              <div className="user_detail d-flex flex-column align-item-start">
                <span className="admin_name">Sumit Verma</span>
                <span className="admin_position"> Admin</span>
              </div>
            </div>
          </div>
        </div>
        <div className="children_con">
          <div className="child_body">
            <div className="childOutlet">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
