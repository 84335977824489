import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Login from "../../pages/Login/Login";
import Navbar from "../Sidebar/Sidebar";
import Sidebar from "../Sidebar/Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import Users from "../../pages/Users/Users";

import Bandwidth from "../../pages/Bandwidth/Bandwidth";
import Inventory from "../../pages/Inventory/Inventory";
import Invoicing from "../../pages/Invoicing/Invoicing";
import Leads from "../../pages/Leads/Leads";
import Logs from "../../pages/Logs/Logs";
// import Nas from "../../pages/Nas/Nas";
import Nasmanagement from "../../pages/Nasmanagement/Nasmenagemtent";
import Notification from "../../pages/Notifications/Notification";
import Plans from "../../pages/Plans/Plans";
import Reports from "../../pages/Reports/Reports";
import UserProfile from "../../pages/Users/UserProfile";
import BillingPlan from "../../pages/BillingPlans/BillingPlan";
import SmsLogs from "../../pages/Logs/SmsLogs";
import NewLead from "../../pages/Leads/NewLeads/NewLead";
import AssignLead from "../../pages/Leads/NewLeads/AssignLead";
import AddBandWidth from "../../pages/Bandwidth/AddBandWidth";
import UploadFile from "../../pages/Bandwidth/UploadFile";
import Nas from "../../pages/Nas/Nas";
import DhcpServer from "../../pages/Nasmanagement/DhcpServer/DhcpServer";
import Firewall from "../../pages/Nasmanagement/Firewall/Firewall";
import Interface from "../../pages/Nasmanagement/Interface/Interface";
import IpPool from "../../pages/Nasmanagement/IpPool/IpPool";

// import NasConfiguration from "../../pages/Nasmanagement/NasConfiguration/NasConfiguration";
import Queue from "../../pages/Nasmanagement/Queue/Queue";
// import Route from "../../pages/Nasmanagement/Routenas/Routenas";
import Routenas from "../../pages/Nasmanagement/Routenas/Routenas";
import IpAddress from "../../pages/Nasmanagement/IpAddress/IpAddress";
import Nasman from "../../pages/Nasmanagement/Nasman/Nasman";
import Invoicesassociated from "../../pages/Invoicing/Invoicesassociated";





const Router = () => {
  return (


    <BrowserRouter>

      <Routes>
        <Route path="" element={<Login />} />
        <Route element={<Sidebar><Outlet /></Sidebar>}>

        <Route path="users" element={<Users />} />

        <Route path="bandwidth" element={<Bandwidth />} />
        <Route path="addBandwidth" element={<AddBandWidth />} />
        <Route path="uploadBandwidth" element={<UploadFile />} />


        <Route path="inventory" element={<Inventory />} />
        <Route path="invoicing" element={<Invoicing />} />
        <Route path="invoiceassociate" element={<Invoicesassociated />} />

        

        <Route path="leads" element={<Leads />} />
        <Route path="newLead" element={<NewLead />} />
        <Route path="assignLead" element={<AssignLead />} />

        <Route path="login" element={<Login />} />
        <Route path="logs" element={<Logs />} />
        <Route path="smsLogs" element={<SmsLogs />} />

        <Route path="nas" element={<Nas/>} />
        <Route path="nasmanagement" element={<Nasmanagement />} />
        <Route path="notification" element={<Notification />} />
        <Route path="plans" element={<Plans />} />
        <Route path="billingplans" element={<BillingPlan />} />
        <Route path="reports" element={<Reports />} />
        <Route path="user" element={<users />} />
        <Route path="userProfile" element={<UserProfile />} />
        <Route path="dhcpserver" element={<DhcpServer />} />
        <Route path="firewall" element={<Firewall />} />


        
        <Route path="interface" element={<Interface />} />
        <Route path="ippool" element={<IpPool />} />
        <Route path="nasman" element={<Nasman />} />
        
        <Route path="queue" element={<Queue />} />
        <Route path="routenas" element={<Routenas />} />
        <Route path="ipaddress" element={<IpAddress/>} />






      </Route>

    </Routes>
    </BrowserRouter >
  );
};

export default Router;
