import React from 'react'
import { LuSearch } from "react-icons/lu";
import style from "./input.module.css"

const SearchInput = ({placeholder,onChange,...props}) => {
  return (
    <div className={style.searchInput_main_con}>
        <input type='text' {...props} placeholder={placeholder || "Search"} onChange={onChange}/>
        <div className={style.search_icon}>
        <LuSearch/>
        </div>
      
    </div>
  )
}

export default SearchInput
