import { useState } from "react";
import Sms from "./Sms";
import { TabPanel, TabView } from "primereact/tabview";

const Logs = () => {
  const [activeIndex, setActiveIndex] = useState(2);

  // const navigate = useNavigate();
  return (
    <div className="Logs_section">
      <h1>Logs</h1>
      <div className="">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Event"></TabPanel>
          <TabPanel header="User"></TabPanel>
          <TabPanel header="SMS">
            <Sms />
          </TabPanel>
          <TabPanel header="E-mail"></TabPanel>
          <TabPanel header="Payment"></TabPanel>
          <TabPanel header="Release Notes"></TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Logs;
