import { TabPanel, TabView } from "primereact/tabview";
import { useState } from "react";
import Notificationplans from "./Notificationplans";
import Billing from "./Billing";


const Notification = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="Notification_section">
      <h1>Notification</h1>

      <div className="mt-3">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Billing"><Billing/></TabPanel>
          <TabPanel header="Plans"><Notificationplans/></TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Notification;
