import { RadioButton } from "primereact/radiobutton";
import React, { useState } from "react";
import "./user.css";
import { Checkbox } from "primereact/checkbox";

import SelectDropDown from "../../components/Inputs/SelectDropDown";
import { InputText } from "primereact/inputtext";
import BackGroudLight from "../../components/Buttons/BackGroudLight";

const AccountInfo = () => {
  const [ingredient, setIngredient] = useState("");
  const [checked, setChecked] = useState(false);
  return (
    <div className="account_info">
      <div className="row">
        <div className="col-md-12">
          <div className="mt-4">
            <span className="light_heading">Select User type:</span>
            <div className=" flex justify-content-start mt-3">
              <div className="flex flex-wrap gap-5">
                <div className="flex align-items-center gap-2">
                  <RadioButton
                    inputId="ingredient1"
                    name="pizza"
                    value="Male"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Male"}
                  />
                  <label htmlFor="ingredient1" className="ml-2 labels">
                    User
                  </label>
                </div>
                <div className="flex align-items-center gap-2">
                  <RadioButton
                    inputId="ingredient2"
                    name="pizza"
                    value="Max / Static IP"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Max / Static IP"}
                  />
                  <label htmlFor="ingredient2" className="ml-2 labels">
                    Max / Static IP
                  </label>
                </div>
                <div className="flex align-items-center gap-2">
                  <RadioButton
                    inputId="ingredient3"
                    name="pizza"
                    value="Multiple Static ip"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Multiple Static ip"}
                  />
                  <label htmlFor="ingredient2" className="ml-2 labels">
                    Multiple Static ip
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* invoice */}
        <div className="col-md-12 mt-4">
          <span className="light_heading">Invoice</span>
        </div>
        <div className="mt-2 col-md-12 flex gap-3">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span>Enable user portal login</span>
        </div>
        {/* // */}

        {/* discount */}
        <div className="col-md-12 mt-4">
          <span className="light_heading">Discount</span>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Type</label>
          <div className="w-100 mt-2">
            <SelectDropDown placeholder={"Select Percentage or Rupees"} />
          </div>
        </div>

        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Value</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder={"Enter Value"} />
          </div>
        </div>
        {/* // */}

        <div className="col-md-12 mt-4">
          <label className="labels">User Name</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder={"Arrav"} />
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <label className="labels">Password</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder={"Enter Value"} />
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <label className="labels">Zone</label>
          <div className="w-100 mt-2">
            <SelectDropDown className="w-100" placeholder={"Select Zone"} />
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <label className="labels">Nas</label>
          <div className="w-100 mt-2">
            <SelectDropDown className="w-100" placeholder={"Select Nas"} />
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <label className="labels">Plan</label>
          <div className="w-100 mt-2">
            <SelectDropDown className="w-100" placeholder={"Select Plan"} />
          </div>
        </div>

        
        <div className="col-md-12 mt-4">
          <label className="labelsRequire">Plan Group</label>
          <div className="w-100 mt-2">
            <SelectDropDown className="w-100" placeholder={"Select Plan Group"} />
          </div>
        </div>

        <div className="col-md-12 mt-4">
          <label className="labelsRequire">Simultaneous Use</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder={"Enter Value"} />
          </div>
        </div>

        <div className="col-md-9 mt-4">
          <label className="labelsRequire">IP Address</label>
          <div className="w-100 mt-2">
            <InputText className="w-100" placeholder={"Select Nas"} />
          </div>
        </div>
       
        <div className="col-md-3 mt-5 pt-2 ">
        {/* <button className="unused">Unused Ip</button> */}
      <BackGroudLight text={'Unused IP'}/>

        </div>

        <div className="col-md-12 flex justify-content-end gap-3 mt-5">
          <button className="cancel_btn"  >Cancel</button>
          <button className="btn_primary_btn">Add</button>


        </div>


      </div>
    </div>
  );
};

export default AccountInfo;
