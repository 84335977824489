import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import SelectDropDown from "../../components/Inputs/SelectDropDown";

const Plans = () => {
  const [checked, setChecked] = useState(false);

  return (
    <div>
      <div className="gap-3">
        <div className="col-md-12 mt-4">
          <span className="">
            <h2>Enable Notification</h2>
          </span>
        </div>
        <div>
          {" "}
          <div className="mt-3 md-12 flex gap-3">
            <Checkbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></Checkbox>
            <span className="dark_content">Enable user Notification</span>
          </div>
        </div>{" "}
      </div>

      <div className="col-md-12 mt-4">
        <span className="">
          <h2>Notification Limit</h2>
        </span>
      </div>

      <div className="col-md-4 mt-4">
        <span className="light_heading">
          <input type="text" className="form-control mt-2" placeholder="40%" />
        </span>
      </div>

      <div className="col-md-12 mt-4">
        <span className="">
          <h2>Restriction Type</h2>
        </span>
      </div>

      <div className="col-md-4 mt-3">
        <div className="w-100 mt-2">
          <SelectDropDown placeholder={"Bandwidth Restriction"} />
        </div>
      </div>

      <div className="col-md-12 mt-4">
        <span className="">
          <h2>Notification Type</h2>
        </span>
      </div>
      <div class="d-flex flex-row " style={{ gap: "13rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">E-mail </span>
        </div>
        <div class="d-flex p-2 gap-3 ">
          {" "}
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">Whatapp</span>
        </div>
      </div>

      <div class="d-flex flex-row " style={{ gap: "14rem" }}>
        <div class="d-flex p-2 gap-2">
          <Checkbox
            onChange={(e) => setChecked(e.checked)}
            checked={checked}
          ></Checkbox>
          <span className="dark_content">SMS </span>
        </div>
      </div>
    </div>
  );
};

export default Plans;
