import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import "../leads.css";
import DropDown from "../../../components/Inputs/DropDown";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import BackGroudLight from "../../../components/Buttons/BackGroudLight";

const NewLeads01 = () => {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState("");

  return (
    <div className="NewLeads01_section">
      <div className="mt-3">
        <h2>New Lead</h2>
      </div>
      <div className="row mt-4">
        <div className="col-md-10 ">
          <div className="light_heading">Personal Details</div>
          <div className="row mt-4">
            <div className="col-md-5 ">
              <label className="labelsRequire">First Name</label>
              <InputText
                className="w-100"
                placeholder="User Details (Summary)"
              />
            </div>
            <div className="col-md-5 ">
              <label className="labelsRequire">Last Name</label>
              <InputText
                className="w-100"
                placeholder="User Details (Summary)"
              />
            </div>
            <div className="col-md-5 mt-3">
              <label className="labelsRequire">Mobile Number</label>
              <InputText
                className="w-100"
                placeholder="User Details (Summary)"
              />
            </div>
            <div className="col-md-5 mt-3 ">
              <label className="labelsRequire">Email ID</label>
              <InputText
                className="w-100"
                placeholder="User Details (Summary)"
              />
            </div>

            {/* company Detail */}
            <div className="light_heading mt-5">Company Details</div>

            <div className="row mt-4">
              <div className="col-md-5 ">
                <label className="labelsRequire">Company Name</label>
                <InputText
                  className="w-100"
                  placeholder="User Details (Summary)"
                />
              </div>
              <div className="col-md-5 ">
                <label className="labelsRequire">Owner</label>
                <DropDown
                  placeholder={"User Details (Summary)"}
                  className="w-100"
                />
              </div>
            </div>

            {/* // */}
          </div>
        </div>

        <div className="col-md-12  mt-5 flex between center">
          <div className="light_heading">Billing Details</div>
          <div className="flex gap-3">
            <Checkbox
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></Checkbox>
            <span>Use billing address as installation address</span>
          </div>
        </div>
        {/* billing address */}
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Billing Address Line 1</label>
          <InputText className="w-100" placeholder="User Details (Summary)" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Billing Address Line 2</label>
          <InputText className="w-100" placeholder="User Details (Summary)" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Billing Address Line 3</label>
          <DropDown className="w-100" placeholder="Select City" />
        </div>

        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Pin Code</label>
          <DropDown className="w-100" placeholder="Select City" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">State</label>
          <DropDown className="w-100" placeholder="Select State" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Country</label>
          <DropDown className="w-100" placeholder="Select State" />
        </div>
        {/* // */}

        {/* permanent address */}
        <div className="col-md-12 mt-4">
          <label className="labelsRequire">Permanent Address</label>

          <div className="flex  h-100">
            <InputTextarea
              className="w-100 h-100"
              placeholder="Write your address"
            />
          </div>
        </div>

        {/* // */}

        {/* Location */}
        <div className="col-md-12  mt-5 flex between center">
          <div className="light_heading">Location</div>
          <div className="">
            <BackGroudLight text={"Get Map"} />
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">User type</label>
          <DropDown className="w-100" placeholder="Home" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Latitude</label>
          <InputText className="w-100" placeholder="User Details (Summary)" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Longitude</label>
          <InputText className="w-100" placeholder="User Details (Summary)" />
        </div>

        {/* // */}

        {/* Bandwidth Restrictions */}
        <div className="col-md-12  mt-5">
          <div className="light_heading">Bandwidth Restrictions</div>
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Bandwidth</label>

          <InputText className="w-100" placeholder="Requested bandwidth" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Speed</label>
          <DropDown className="w-100" placeholder="Mbps" />
        </div>
        <div className="col-md-4 mt-4">
          <label className="labelsRequire">Source</label>
          <DropDown className="w-100" placeholder="Select source" />
        </div>

        {/* // */}

        {/* Comments */}
        <div className="col-md-12  mt-5">
          <div className="light_heading">Comments</div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Other Comments</label>
          <div className="flex  h-100">
            <InputTextarea
              className="w-100 h-100"
              placeholder="Write your comments here"
            />
          </div>
        </div>
        <div className="col-md-6 mt-4">
          <label className="labelsRequire">Activation Comments</label>
          <div className="flex  h-100">
            <InputTextarea
              className="w-100 h-100"
              placeholder="Write your comments here"
            />
          </div>
        </div>

        {/* // */}
        <div className="flex end mt-5">
          <button className="btn_primary_btn">Next</button>
        </div>
      </div>
    </div>
  );
};

export default NewLeads01;
