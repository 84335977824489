import React, { useState } from "react";
import DropDown from "../../components/Inputs/DropDown";
import CreateButton from "../../components/Buttons/CreateButton";
import SearchInput from "../../components/Inputs/SearchInput";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { useNavigate } from "react-router";
import { NasData } from "../../DummyData/DummyData";
import { RiEditBoxLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const Nas = () => {
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const Ip = [
    { name: "Softnet (103.161.53.211)" },
    { name: "Softnet (103.161.53.212)" },
    { name: "Softnet (103.161.53.213)" },
    { name: "Softnet (103.161.53.214)" },
    { name: "Softnet (103.161.53.215)" },
  ];
  return (
    <section>
      <div className="flex between ">
        <div>
          <h1>Nas</h1>
        </div>
        <div className="flex gap-3">
          <div>
            <DropDown placeholder={"Softnet (103.161.53.218)"} option={Ip} />
          </div>
          <div>
            <CreateButton addIcon text={"Add Nas"} />
          </div>
        </div>
      </div>
      <div className="flex between mt-4">
        <div>
          <SearchInput placeholder={"Enter a name here to search"} />
        </div>
        <div>
          <DropDown placeholder={"All Zones"} />
        </div>
      </div>

      <div className="table_section">
        <BorderedTable>
          <div className=" mt-3">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              value={NasData}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>

              <Column field="user" header="User"></Column>
              <Column field="ipAddress" header="IP Address"></Column>
              <Column field="upTime" header="Uptime"></Column>
              <Column field="rxSpeed" header="rxspeed"></Column>
              <Column field="txSpeed" header="txspeed"></Column>
              <Column field="rxBytes" header="rxbytes"></Column>
              <Column field="txBytes" header="txbytes"></Column>
              <Column
                field="logOut"
                header="Logout"
                body={(rowData) => (
                  <div className="flex gap-3">
                    <RiEditBoxLine size={20} />
                    <MdDelete size={20} />
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </section>
  );
};

export default Nas;
