import React from 'react'
import style from "./button.module.css"
import { CiFolderOn } from "react-icons/ci";

const BackGroudLight = ({addIcon , text , className,...props}) => {
  return (
   <button className={`${style.BackGroudLight} flex center`} {...props}>
      {addIcon ? <span className='mb-'>
        <CiFolderOn  size={20} />
    </span> : null}
    {text}


   </button>
  )
}

export default BackGroudLight
