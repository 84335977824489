import "./App.css";
import Router from "./components/routes/Router";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";

function App({ pageProps }) {
  return (
    <PrimeReactProvider>
      <Router {...pageProps} />
    </PrimeReactProvider>
   
  );
}

export default App;
