import React, { useState } from "react";
import CreateButton from "../../components/Buttons/CreateButton";
import BackGroudLight from "../../components/Buttons/BackGroudLight";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BorderedTable } from "../../components/Tables/BorderedTable";
import { useNavigate } from "react-router";
import { InventoryMangemnet } from "../../DummyData/DummyData";
import { RiEditBoxLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const InventoryManagement = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="InventoryManagement_section mt-3">
      <div className="flex between center">
        <h2>Inventory Management</h2>
        <div className="flex gap-3 center">
          <span className="export">Import</span>
          <BackGroudLight text={"Request Item"} />
          <span className="line"></span>
          <CreateButton addIcon text={"Create Item Category"} />
        </div>
      </div>

      <div className="table_section">
        <BorderedTable>
          <div className=" mt-3">
            <div className="flex justify-content-center align-items-center mb-4 gap-2">
              {/* <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} /> */}
              {/* <label htmlFor="input-rowclick">Row Click</label> */}
            </div>
            <DataTable
              value={InventoryMangemnet}
              selectionMode={rowClick ? null : "checkbox"}
              selection={selectedProducts}
              onSelectionChange={(e) => setSelectedProducts(e.value)}
              dataKey="id"
              tableStyle={{ minWidth: "50rem" }}
            >
              <Column
                selectionMode="multiple"
                headerStyle={{ width: "3rem" }}
              ></Column>
              <Column
                field="name"
                header="Name"
                style={{ width: "80%" }}
              ></Column>
              <Column
                field="action"
                header="Action"
                body={(rowData) => (
                  <div className="flex gap-2">
                    <RiEditBoxLine size={20} />
                    <MdDelete size={20} />
                  </div>
                )}
              ></Column>
            </DataTable>
          </div>
        </BorderedTable>
      </div>
    </div>
  );
};

export default InventoryManagement;
